<template>
  <pre>
  <code>
    <strong>&lt;script </strong>type="<span class="pre-code-string">text/javascript</span>"<strong>&gt;</strong>
      (<strong>function</strong>(w,d,s,u,o){
        w[o] = ((w[o]) ? w[o] : []);
        w[o].push({
          memberId: "<span class="pre-code-string">{{ data.memberId }}</span>",
          apiKey: "<span class="pre-code-string">{{ data.apiKey }}</span>" ,
          spaceName: <span class="pre-code-string">"{{ data.spaceName }}"</span>,
          widgetId: <span class="pre-code-string">"{{ data.widgetId }}"</span>,
        });
        <strong>var</strong> a=d.createElement(s), m=d.getElementsByTagName(s)[0];
        a.async=1;a.src=u;m.parentNode.insertBefore(a,m);
      })(<span class="pre-code-string__win">window,document</span>,<span class="pre-code-string">'script'</span>,
       <span class="pre-code-string">'https://gateway-staging.ziqni.com/assets/javascripts/achievements-widget.js'</span>,
       <span class="pre-code-string">"_clAchievementOptions"</span>);
    <strong>&lt;/script&gt;</strong>
    <strong>&lt;div</strong> class="<span class="pre-code-string">zq-achievement-widget</span>"&gt;<strong>&lt;/div&gt;</strong>
  </code>
  </pre>
</template>

<script>
export default {
  props: {
    data: Object
  }
}
</script>

<style lang="scss">
.pre-code-string {
  color: #880000;

  &__win {
    color: #1F811F;
  }
}
</style>